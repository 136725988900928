@media only screen and (max-width: 768px) {

    .navbar-default {
        background-color: #ffffff;
        border-color: #FFFFFF;
    }
    .navbar-toggle {
        .icon-bar {
            background-color: white;
            height: 3px;
        }
    }
    .navbar-default .navbar-collapse, .navbar-default .navbar-form {
        border: 0px solid #ffffff;
        background-color: $default-color;
        margin-top: 10px;
    }
    .navbar-default .navbar-toggle, .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
            padding: 8px;
            width: 44px;
            height: 40px;
            display: block;
            margin-right: 30%;

    }
    #header {
        text-align:center;
        span {
            font-size: 21px !important;
        }
    }
    #header span span {
            font-size: 24px !important;
    }

    #header a:hover, #header a:focus {
            background-color: #217db9;
    }
    .call_button {
        width: 44px;
        height: 40px;
        padding: 0;
        font-size: 31px;
        position: absolute;
        z-index: 10001;
        left: 0;
        display: inline;
        border: 1px solid rgba(0,0,0,0);
    }
    .btn-default:hover {
            border-color: #217db9;
    }
    #header #start_search {
        width: 44px;
    }

    .relative {
        position: relative;
        width:152px;
        margin: 0 auto;
        margin-bottom: -50px;
    }
    .search_button_xs {
        position: relative;
        z-index: 10001;
        width: 44px;
        height: 40px;
        padding: 0;
        display: inline-block;
        margin-left: 54px;
        border: 1px solid rgba(0,0,0,0);
        font-size: 31px;
        font-size: 29px;
        line-height: 12px;
    }
    .navbar-toggle {
            position: relative;
            z-index: 10001;
            padding: 8px;
            width: 44px;
            height: 40px;
            background-color: $default-color;
            margin: 0 !important;
            display: inline-block;
    }
    .panel-default .panel-heading {
        min-height: 58px;
        height: auto;
    }
    #myModal {
        z-index: 100000;
    }
    #search_word_xs {
        width: 100%;
        height: 40px;
        padding: 5px;
    }
    .title {
        font-size: 38px;
    }
    .panel-default .panel-heading {
        font-size: 18px;
    }
    .news .heading {
            font-size: 18px;
    }
    .carousel-inner {
            height: 100px;;
    }
}