/*-- CLASS FOR INHERIT START --*/
.fontAwesome {
    font-size: 36px;
    display: block;
    float: left;
    margin: 0px 10px 0px -3px;
}
.tinymceContent {
    width: 100%;
    overflow: hidden;
    img {
        max-width: 100% !important;
        height: auto !important;
    }
}
/*-- CLASS FOR INHERIT   END --*/


.titleTopRight {
    p {
        text-align: right;
        font-size: 18px;
    }
}
#menu .dropdown-submenu {
    position: relative;
}
nav {
    font-size: 16px;
    .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -18px;
        left: 42%;
    }
    .dropdown-menu {
        li:first-of-type {
            display: none;
        }
        top: 90%;
        font-size: 16px;
        background-color: white;
        border-radius: 6px !important;
        a {
            color: $light-text;
        }
        a:hover {
            color: $default-color;
            background-color: white;
        }
        .divider {
            margin: 5px 12px;
        }
    }
}
.pageContainer {
    min-height: 60vh;
    overflow: hidden;
    @media only screen and (max-width: 767px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    #sliderTop {
        margin-bottom: 20px;
    }
    .panel-heading {
        font-size: 20px;
        @media only screen and (max-width: 1199px) {
            font-size: 18px;
        }
        height: 58px;
        line-height: 38px;
    }
    .fa {
        @extend .fontAwesome;
    }
    .panel-primary {
        margin-bottom: 36px;
        border: 0px solid white;
        .panel-body {
            background-color: $panel-body;
        }
    }
    .widget3 {
        color: $light-text;
        img {
            margin-top: 10px
        }
        .heading {
            color: $default-color;
            font-weight: bold;
            font-size: 20px;
            height: 58px;
            line-height: 38px;
            padding: 10px 15px;
            border-bottom: 3px solid $default-color;
            margin-bottom: 5px;
        }
        .top_hr {
            h4 {
                border-top: 1px solid $default-color;
                padding-top: 10px;
            }
        }
        .item1 {
            h4 {
                @media only screen and (min-width: $screen-sm) and (max-width: $screen-lg - 1px) {
                    border-top: 0px solid $default-color;
                }
            }
        }
        .readMore {
            text-align: left;
            font-size: 25px;
            padding-left: 0;
            margin-top: -17px;
            display: block;
            border-top: 1px solid #AAAAAA;
            float: none;
            padding: 8px 0;
        }
    }
    .readMore {
        float: right;
    }
    .listSpace {
        height: 20px;
        clear: both
    }
}
.imageGallery {
    color:#fff;
    font-size:0.0001em;
    img {
        width: 24%;
        margin: 10px 0.5%;
        border-radius: 5px;
        @media only screen and (max-width: $screen-sm) {
            width: 32.3%;
        }
        @media only screen and (max-width: $screen-xs) {
            width: 49%;
        } 
    }
}
.fileList {
    text-align: center;
    .file {
        min-height: 170px;
        img {
            margin: 0 auto;
        }
    }
}
footer {
    border-top: 9px solid $default-color;
    background-color: #222;
    min-height: 20vh;
    padding-top: 20px;
    * {
        color: white;
    }
}
