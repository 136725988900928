.fc-state-active.fc-month-button {
    display: none;
}
.fc-month-view  .fc-content-skeleton tbody {
    // display: none;
}
.fc td {
    border-width: 0 !important;
}
.fc-day-number {
    line-height: 20px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: #444444;
}
.fc-center {
    h2 {
        font-size: 16px;
        font-weight: 700;
    }
}
.fc-sun {
    .fc-day-number {
        color: #8C0000;
    }
}
.eventDay {
    .fc-day-number {
        color: $default-color;
    }
}
.fc-state-default.fc-button {
    margin-top: -5px;
    border: none;
    background-color: rgba(0,0,0,0);
    background-image: initial;
    background-repeat: initial;
    border-color: initial;
    color: initial;
    text-shadow: initial;
    box-shadow: initial;
}
.fc-icon-left-single-arrow:after {
    font-size: 0 !important;
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-right: 8px solid black;
    top: -4px !important;
}
.fc-icon-right-single-arrow:after {
    font-size: 0 !important;
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-left: 8px solid black;
    top: -4px !important;
}
.fc-widget-header {
    thead {
        background-color: black;
        color: white;
        line-height: 35px;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        th {
            border-width: 0;
        }
    }
}
.fc-head {
    border-bottom: 20px solid $panel-body !important;
}
.fc-basic-view .fc-body .fc-row {
    min-height: 37px !important;
}
.fc-unthemed {
    td.fc-today {
        background-color: initial !important;
    }
    .fc-month-view td.fc-today.fc-day::after {
        content: 'border';
        border: 1px solid black;
        font-size: 0;
        width: 67%;
        margin-left: 11%;
        height: 22px;
        display: block;
    }
}
.fc-state-default.fc-button.fc-month-button {
    background-color: black;
    color: white;
    border: 0;
    border-radius: 0;
    font-weight: 700;
}
.fc-month-view  {
    .fc-event-container {
        .fc-event {
            border: 1px solid $second-color !important;
            border-radius: 0;
            margin: 1px !important;
            padding: 0 !important;
            height: 0;
            .fc-content {
                .fc-title {
                    font-size: 0;
                }
            }
        }
    }
}
.fc-basicDay-view {
    .fc-event-container {
        .fc-event {
            background-color: initial !important;
            border: 1px solid black !important;
            border-radius: 0;
            margin: 0px 0 10px 0 !important;
            padding: 10px !important;
            .fc-content {
                .fc-title {
                    font-size: 18px;
                    color: #444444;
                    weight: 700;
                }
            }
        }
    }
}