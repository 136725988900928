.listGalleryTitle {
    margin-bottom: 25px;
}
.galleryLink {
    text-decoration: none !important;
    display: block;
    height: 250px;
    background-color: #2c3136;
    margin: 12px 7px;
    background-image: url(/media/default/noImageNews.jpg);
    background-size: cover;
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.28);
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.28);
    &:hover {
        .shadowed {
            height: 250px;
            background: rgba(0, 0, 0, 0.6);
            top: 0;
        }
    }
    .shadowed {
        height: 150px;
        background: red; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); /* For Firefox 3.6 to 15 */
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)); /* Standard syntax (must be last) */
        position: relative;
        top: 100px;
        transition: background .5s, top .5s, height .5s;
        .text {
            padding: 65px 10px 10px 10px;
            font-size: 25px;
            color: white;
            font-weight: 700;
            text-decoration: none !important;
            height: 135px;
            overflow: hidden;
        }
    }
}