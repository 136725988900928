#header {
    font-size: 18px;
    padding: 25px 0 18px 3px;
    .leftSideTop {
        .logoImage  {
            float: left;
            margin-right: 4%;
            max-width: 32%;
        }
        p {
            margin-bottom: 0;
            color: black;
            font-family: 'Alegreya SC', serif;
            &.green {
                font-family: 'Lato';
                color: $default-color;
                text-transform: none;
            }
        }
    }
    span {
        color: $default-color;
        font-size: 20px;
        line-height: 10px;
        span {
            font-size: 23px;
        }
    }
    .to_right {
        text-align: right;
        font-size: 14px;
        color: #66635B;
        span {
            font-size: 18px;
            margin-left: 10px;
        }
    }
    .search {
        margin-top: 24px;
    }
    #search_word-error {
        position: absolute;
        top: 25px;
        right: 134px;
        color: red;
    }
    #search_word,
    #search_word_xs {
        margin-top: 2px;
        width: 290px;
        height: 40px;
        border: 1px solid #8D8D8D;
    }
    #start_search {
        border: 0px solid white;
        height: 40px;
        width: 40px;
        margin-top: -2px;
        margin-left: 3px;
        padding: 0;
        i {
            margin-top: -4px;
            margin-left: 1px;
            font-size: 36px;
        }
    }
    a:hover, a:focus {
        text-decoration: none;
        background-color: white;
    }
}
nav {
    .navbar-nav > li > a {
        text-transform: uppercase;
        padding: 23px 7px;
    }
    .navbar-brand {
        i {
            font-size: 24px;
            margin-top: 4px;
        }
    }
    font-size: 15px;
    .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -18px;
        left: 15%;
        @media only screen and (max-width: 767px) {
            opacity: 0;
        }
    }
    .dropdown-menu {
        li:first-of-type {
            display: none;
        }
        top: 90%;
        font-size: 16px;
        background-color: white;
        border-radius: 6px !important;
        a {
            color: #5F5F5F;
        }
        a:hover {
            color: $default-color;
            background-color: white;
        }
        .divider {
            margin: 5px 12px;
        }
    }
}



/** Carousel START **/
#carousel-example-generic {
    margin-top: -10px;
    margin-bottom: 30px;
    .carousel-indicators {
        bottom: 3px;
        li, .active  {
            width: 26px;
            height: 26px;
            border-radius: 13px;
            border: 4px solid white;
            background-color: $panel-body;
        }
        .active {
            background-color: $default-color;
        }
    }

    .carousel-caption {
        left: 0% !important;
        right: 50% !important;
        bottom: 60px !important;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        text-shadow: 0 1px 2px rgba(0,0,0,0.6);
        background-color: rgba(0, 0, 0, 0.55);
        font-weight: bold;
        font-size: 18px;
    }
}
/** Carousel   END **/
/** AKTUALNOŚCI START **/
.widget3 {
    font-family: 'Lato';
    .heading {
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 7px;
        font-size: 20px;
        color: $default-color;
        line-height: 38px;
        border-bottom: 3px solid $default-color;
        i {
            font-size: 45px;
            display: block;
            float: left;
            margin: 0px 10px 0px -11px;
        }
    }
    .singleNewsStartPage {
        display: block;
        margin-top: 25px;
        margin-bottom: 36px;
        color: #403D31;
        text-decoration: none !important;
        &:hover {
            color: #403D31;
        }
        h2 {
            font-size: 25px;
            font-weight: 700;
            margin-top: 18px;
            margin-left: 3px;
        }
        p {
            max-width: 90%;
        }
    }
}
/** AKTUALNOŚCI   END **/
.jumbotron-blue {
    background-color: $default-color;
    margin: 20px 0;
    @media only screen and (max-width: 991px) {
        > div:first-of-type {
            padding: 0 !important;
        }
    }
}
#contact {
    text-align: center;
    color: white;
    h3 {
        font-size: 30px;
        text-transform: uppercase;
        padding-left: 4%;
        margin-top: 50px;
        @media only screen and (max-width: 1199px) {
            margin-top: 25px;
            font-size: 25px;
        }
    }
    p {
        padding-left: 4%;
        padding-bottom: 0;
        margin-bottom: 2px;
    }
    .tekst {
        font-size: 18px;
        @media only screen and (max-width: 1199px) {
            font-size: 15px;
        }
        @media only screen and (max-width: 991px) {
            font-size: 13px;
        }
    }
    .left {
        padding-left: 7%;
    }
    img {
        margin: 15px 0px;
        @media only screen and (max-width: 767px) {
            margin: 15px auto;
        }
    }
    a {
        color: white;
    }
} 
/** RECOMMENDED START **/
#reccomended {
    color: #444444;
    font-size: 28px;
    line-height: 80px;
    .img-responsive {
        margin: 0 auto;
        width: auto;
        max-width: 100%;
    }
    margin-bottom: 30px;
}
/** RECOMMENDED   END **/
/** FOOTER START **/
footer {
    color: white;
    background-color: #202121;
    border-top: 9px solid $default-color;
    .menuBottom {
        margin-top: 38px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.19);
        padding-bottom: 40px;
        > ul {
            padding-left: 0;
            > li {
                list-style: none;
                display: none;
            }
            .dropdown-submenu {
                display: block;
                float: left;
                @media only screen and (max-width: 1199px) {
                    width: 33.33%;
                    height: 350px;
                }
                > a {
                    text-transform: uppercase;
                    pointer-events: none;
                    font-size: 15px;
                    font-weight: 700;
                    .caret {
                        display: none;
                    }
                }
                .dropdown-submenu {
                    display: none;
                }
                .dropdown-menu {
                    border: initial;
                    box-shadow: initial;
                    position: initial;
                    float: none;
                    display: block;
                    background: initial;
                    a {
                        padding: 4px 56px 6px 0;
                        font-size: 13px;
                        color: #F0EAE5;
                        &:hover {
                            background-color: initial;
                            color: white;
                        }
                    }
                    .divider {
                        display: none;
                    }
                }
            }
        } 
    }
    .secondSection {
        margin-top: 44px;
        margin-bottom: 46px;
        @media only screen and (max-width: 767px) {
            text-align: center;
        }
    }
    a {
        color:white;
    }
    .tekst {
        font-size: 16px;
        @media only screen and (max-width: 991px) {
            font-size: 13px;
        }
    }
    .col-by-5 {
        margin-top: 50px;
        ul {
            padding-left: 0;
        }
        li {
            list-style-type: none;
            padding: 5px;
            a {
                color: white;
                font-size: 14px;
            }
        }
    }
    .category {
        padding: 5px;
        text-transform: uppercase;
        font-size: 18px;
        color: white;
        font-weight: bold;
    }
    p.tekst {
        margin-bottom: 19px;
    }
    .right {
        p {
            float: left;
            margin-right: 18px;
            font-size: 18px;
            @media only screen and (max-width: 991px) {
                float: initial;
            }
            @media only screen and (max-width: 767px) {
                margin-top: 40px;
            }
        }
    }
    .img-responsive {
        width: 17%;
        display: inline-block;
        padding: 2px;
        float: left;
        @media only screen and (max-width: 1199px) {
            width: 16%;
        }
        @media only screen and (max-width: 767px) {
            width: 22%;
            margin: 1.5%;
            padding: 0;
        }
    }
}
#optimal {
    padding-top: 15px;
    color: #aaaaaa;
    border-top: 1px solid rgba(255, 255, 255, 0.19);
    background-color: #1C1C1C;
    padding-bottom: 20px;
    @media only screen and (max-width: 767px) {
        text-align: center;
    }
    i {
        display: inline-block;
        vertical-align: text-bottom;
    }
    .realization {
        text-align: right;
        @media only screen and (max-width: 767px) {
            text-align: center;
            padding-top: 10px;
        }
    }
    * {
        color: #AAAAAA;
        font-size: 12px;
    }
}
/** FOOTER   END **/



@media only screen and (min-width: 1200px) {
    #daily_reading {
        .col-lg-3 {
            width: 20%;
        }
        .col-lg-9 {
            width: 80%;
            padding-left: 4%;
        }
    }
}



.recommended {
    a {
        display: inline;
        margin: 0 auto;
        text-align: center;
        @media only screen and (max-width: $screen-xs - 1px) {
            width: 100%;
        }
    }
    .title {
        color: #444444;
        font-size: 29px;
        font-weight: 300;
        margin-top: 20px;
        margin-bottom: 12px;
    }
    img {
        width: 14%;
        margin: 10px 3%;
        border-radius: 0;
        opacity: .6;
        -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
        filter: grayscale(100%);
        @media only screen and (max-width: $screen-md - 1px) {
            width: 38%;
            margin: 10px 6%;
        }
        @media only screen and (max-width: $screen-xs - 1px) {
            width: auto;
            display: block;
            margin: 30px auto;
            max-width: 100%
        }
    }
    img:hover, img:active {
        opacity: 1;
        -webkit-filter: grayscale(0%); /* Chrome, Safari, Opera */
        filter: grayscale(0%);
    }
}
#sliderTop {
    margin-top: -10px;
    margin-bottom: 30px;
    .carousel-indicators {
        bottom: 3px;
        li, .active  {
            width: 26px;
            height: 26px;
            border-radius: 13px;
            border: 4px solid white;
            background-color: #eaeaea;
        }
        .active {
            background-color: $default-color;
        }
    }

    .carousel-caption {
        left: 0% !important;
        right: 50% !important;
        bottom: 60px !important;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        text-shadow: 0 1px 2px rgba(0,0,0,0.6);
        background-color: rgba(0, 0, 0, 0.55);
        font-weight: bold;
        font-size: 18px;
    }
}
.logoImage  {
    float: left;
    margin-right: 4%;
}
.quote {
    font-family: "Lato";
    margin: 50px 11px 10px 11px;
    font-size: 26px;
    font-style: italic;
line-height: 31px;
}
.quoteAutor {
    text-align: right;
    margin: 25px 27px 53px 0;
}
.widget1 {
    margin-top: 50px;
}
.clear {
    clear: both;
}
@media only screen and (max-width: 767px) {
    .col-xxs-12  {
        width: 100%;
    }
}


.facebookWidget {
    position: fixed;
    top: 20%;
    right: -10px;
    z-index: 1;
    img {
        vertical-align: top;
        float: left;
        &:hover + .containerFb {
            width: 349px;
            border: 5px solid #4867AA;
        }
    }
    .containerFb {
        width: 0;
        float: left;
        transition: width 0.5s;
        border: 5px solid #4867AA;
        &:hover {
            width: 349px;
        }
    }
}

.librus {
    position: fixed;
    top: 20%;
    left: 0;
}
.activeMenu {
    color: black !important;
    > a {
        color: black !important;
    }
}
 