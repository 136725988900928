#menu .dropdown-submenu {
    position: relative;
}
nav {
    font-size: 16px;
    .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-bottom: 10px solid white;
        position: absolute;
        top: -18px;
        left: 42%;
    }
    .dropdown-menu {
        li:first-of-type {
            display: none;
        }
        top: 90%;
        font-size: 16px;
        background-color: white;
        border-radius: 6px !important;
        a {
            color: $light-text;
        }
        a:hover {
            color: $default-color;
            background-color: white;
        }
        .divider {
            margin: 5px 12px;
        }
    }
}


.dropdown-submenu {
    position: relative;
    .dropdown-submenu .dropdown-menu::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        background-color: transparent;
        border: 10px solid transparent;
        border-right: 10px solid white;
        position: absolute;
        top: 10px;
        left: -19px;
    }
    .dropdown-submenu {
        .caret {
            display: none;
        }
    }
    .dropdown-submenu >.dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px;
        border-radius: 0 6px 6px 6px;
    }
    .dropdown-submenu > a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #ccc;
        margin-top: 5px;
        margin-right: -10px;
    }
    .dropdown-submenu .hover + .dropdown-menu {
        display: block;
    }
}
