@charset "UTF-8";
/*-- CLASS FOR INHERIT START --*/
.fontAwesome, .pageContainer .fa {
  font-size: 36px;
  display: block;
  float: left;
  margin: 0px 10px 0px -3px; }

.tinymceContent, .pageContent, .newsContent {
  width: 100%;
  overflow: hidden; }
  .tinymceContent img, .pageContent img, .newsContent img {
    max-width: 100% !important;
    height: auto !important; }

/*-- CLASS FOR INHERIT   END --*/
.titleTopRight p {
  text-align: right;
  font-size: 18px; }

#menu .dropdown-submenu {
  position: relative; }

nav {
  font-size: 16px; }
  nav .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 42%; }
  nav .dropdown-menu {
    top: 90%;
    font-size: 16px;
    background-color: white;
    border-radius: 6px !important; }
    nav .dropdown-menu li:first-of-type {
      display: none; }
    nav .dropdown-menu a {
      color: #555; }
    nav .dropdown-menu a:hover {
      color: #7BC623;
      background-color: white; }
    nav .dropdown-menu .divider {
      margin: 5px 12px; }

.pageContainer {
  min-height: 60vh;
  overflow: hidden; }
  @media only screen and (max-width: 767px) {
    .pageContainer {
      padding-left: 25px;
      padding-right: 25px; } }
  .pageContainer #sliderTop {
    margin-bottom: 20px; }
  .pageContainer .panel-heading {
    font-size: 20px;
    height: 58px;
    line-height: 38px; }
    @media only screen and (max-width: 1199px) {
      .pageContainer .panel-heading {
        font-size: 18px; } }
  .pageContainer .panel-primary {
    margin-bottom: 36px;
    border: 0px solid white; }
    .pageContainer .panel-primary .panel-body {
      background-color: #eaeaea; }
  .pageContainer .widget3 {
    color: #555; }
    .pageContainer .widget3 img {
      margin-top: 10px; }
    .pageContainer .widget3 .heading {
      color: #7BC623;
      font-weight: bold;
      font-size: 20px;
      height: 58px;
      line-height: 38px;
      padding: 10px 15px;
      border-bottom: 3px solid #7BC623;
      margin-bottom: 5px; }
    .pageContainer .widget3 .top_hr h4 {
      border-top: 1px solid #7BC623;
      padding-top: 10px; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .pageContainer .widget3 .item1 h4 {
        border-top: 0px solid #7BC623; } }
    .pageContainer .widget3 .readMore {
      text-align: left;
      font-size: 25px;
      padding-left: 0;
      margin-top: -17px;
      display: block;
      border-top: 1px solid #AAAAAA;
      float: none;
      padding: 8px 0; }
  .pageContainer .readMore {
    float: right; }
  .pageContainer .listSpace {
    height: 20px;
    clear: both; }

.imageGallery {
  color: #fff;
  font-size: 0.0001em; }
  .imageGallery img {
    width: 24%;
    margin: 10px 0.5%;
    border-radius: 5px; }
    @media only screen and (max-width: 768px) {
      .imageGallery img {
        width: 32.3%; } }
    @media only screen and (max-width: 480px) {
      .imageGallery img {
        width: 49%; } }

.fileList {
  text-align: center; }
  .fileList .file {
    min-height: 170px; }
    .fileList .file img {
      margin: 0 auto; }

footer {
  border-top: 9px solid #7BC623;
  background-color: #222;
  min-height: 20vh;
  padding-top: 20px; }
  footer * {
    color: white; }

#header {
  font-size: 18px;
  padding: 25px 0 18px 3px; }
  #header .leftSideTop .logoImage {
    float: left;
    margin-right: 4%;
    max-width: 32%; }
  #header .leftSideTop p {
    margin-bottom: 0;
    color: black;
    font-family: 'Alegreya SC', serif; }
    #header .leftSideTop p.green {
      font-family: 'Lato';
      color: #7BC623;
      text-transform: none; }
  #header span {
    color: #7BC623;
    font-size: 20px;
    line-height: 10px; }
    #header span span {
      font-size: 23px; }
  #header .to_right {
    text-align: right;
    font-size: 14px;
    color: #66635B; }
    #header .to_right span {
      font-size: 18px;
      margin-left: 10px; }
  #header .search {
    margin-top: 24px; }
  #header #search_word-error {
    position: absolute;
    top: 25px;
    right: 134px;
    color: red; }
  #header #search_word,
  #header #search_word_xs {
    margin-top: 2px;
    width: 290px;
    height: 40px;
    border: 1px solid #8D8D8D; }
  #header #start_search {
    border: 0px solid white;
    height: 40px;
    width: 40px;
    margin-top: -2px;
    margin-left: 3px;
    padding: 0; }
    #header #start_search i {
      margin-top: -4px;
      margin-left: 1px;
      font-size: 36px; }
  #header a:hover, #header a:focus {
    text-decoration: none;
    background-color: white; }

nav {
  font-size: 15px; }
  nav .navbar-nav > li > a {
    text-transform: uppercase;
    padding: 23px 7px; }
  nav .navbar-brand i {
    font-size: 24px;
    margin-top: 4px; }
  nav .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 15%; }
    @media only screen and (max-width: 767px) {
      nav .dropdown-menu::before {
        opacity: 0; } }
  nav .dropdown-menu {
    top: 90%;
    font-size: 16px;
    background-color: white;
    border-radius: 6px !important; }
    nav .dropdown-menu li:first-of-type {
      display: none; }
    nav .dropdown-menu a {
      color: #5F5F5F; }
    nav .dropdown-menu a:hover {
      color: #7BC623;
      background-color: white; }
    nav .dropdown-menu .divider {
      margin: 5px 12px; }

/** Carousel START **/
#carousel-example-generic {
  margin-top: -10px;
  margin-bottom: 30px; }
  #carousel-example-generic .carousel-indicators {
    bottom: 3px; }
    #carousel-example-generic .carousel-indicators li, #carousel-example-generic .carousel-indicators .active {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: 4px solid white;
      background-color: #eaeaea; }
    #carousel-example-generic .carousel-indicators .active {
      background-color: #7BC623; }
  #carousel-example-generic .carousel-caption {
    left: 0% !important;
    right: 50% !important;
    bottom: 60px !important;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.55);
    font-weight: bold;
    font-size: 18px; }

/** Carousel   END **/
/** AKTUALNOŚCI START **/
.widget3 {
  font-family: 'Lato'; }
  .widget3 .heading {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 7px;
    font-size: 20px;
    color: #7BC623;
    line-height: 38px;
    border-bottom: 3px solid #7BC623; }
    .widget3 .heading i {
      font-size: 45px;
      display: block;
      float: left;
      margin: 0px 10px 0px -11px; }
  .widget3 .singleNewsStartPage {
    display: block;
    margin-top: 25px;
    margin-bottom: 36px;
    color: #403D31;
    text-decoration: none !important; }
    .widget3 .singleNewsStartPage:hover {
      color: #403D31; }
    .widget3 .singleNewsStartPage h2 {
      font-size: 25px;
      font-weight: 700;
      margin-top: 18px;
      margin-left: 3px; }
    .widget3 .singleNewsStartPage p {
      max-width: 90%; }

/** AKTUALNOŚCI   END **/
.jumbotron-blue {
  background-color: #7BC623;
  margin: 20px 0; }
  @media only screen and (max-width: 991px) {
    .jumbotron-blue > div:first-of-type {
      padding: 0 !important; } }

#contact {
  text-align: center;
  color: white; }
  #contact h3 {
    font-size: 30px;
    text-transform: uppercase;
    padding-left: 4%;
    margin-top: 50px; }
    @media only screen and (max-width: 1199px) {
      #contact h3 {
        margin-top: 25px;
        font-size: 25px; } }
  #contact p {
    padding-left: 4%;
    padding-bottom: 0;
    margin-bottom: 2px; }
  #contact .tekst {
    font-size: 18px; }
    @media only screen and (max-width: 1199px) {
      #contact .tekst {
        font-size: 15px; } }
    @media only screen and (max-width: 991px) {
      #contact .tekst {
        font-size: 13px; } }
  #contact .left {
    padding-left: 7%; }
  #contact img {
    margin: 15px 0px; }
    @media only screen and (max-width: 767px) {
      #contact img {
        margin: 15px auto; } }
  #contact a {
    color: white; }

/** RECOMMENDED START **/
#reccomended {
  color: #444444;
  font-size: 28px;
  line-height: 80px;
  margin-bottom: 30px; }
  #reccomended .img-responsive {
    margin: 0 auto;
    width: auto;
    max-width: 100%; }

/** RECOMMENDED   END **/
/** FOOTER START **/
footer {
  color: white;
  background-color: #202121;
  border-top: 9px solid #7BC623; }
  footer .menuBottom {
    margin-top: 38px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.19);
    padding-bottom: 40px; }
    footer .menuBottom > ul {
      padding-left: 0; }
      footer .menuBottom > ul > li {
        list-style: none;
        display: none; }
      footer .menuBottom > ul .dropdown-submenu {
        display: block;
        float: left; }
        @media only screen and (max-width: 1199px) {
          footer .menuBottom > ul .dropdown-submenu {
            width: 33.33%;
            height: 350px; } }
        footer .menuBottom > ul .dropdown-submenu > a {
          text-transform: uppercase;
          pointer-events: none;
          font-size: 15px;
          font-weight: 700; }
          footer .menuBottom > ul .dropdown-submenu > a .caret {
            display: none; }
        footer .menuBottom > ul .dropdown-submenu .dropdown-submenu {
          display: none; }
        footer .menuBottom > ul .dropdown-submenu .dropdown-menu {
          border: initial;
          box-shadow: initial;
          position: initial;
          float: none;
          display: block;
          background: initial; }
          footer .menuBottom > ul .dropdown-submenu .dropdown-menu a {
            padding: 4px 56px 6px 0;
            font-size: 13px;
            color: #F0EAE5; }
            footer .menuBottom > ul .dropdown-submenu .dropdown-menu a:hover {
              background-color: initial;
              color: white; }
          footer .menuBottom > ul .dropdown-submenu .dropdown-menu .divider {
            display: none; }
  footer .secondSection {
    margin-top: 44px;
    margin-bottom: 46px; }
    @media only screen and (max-width: 767px) {
      footer .secondSection {
        text-align: center; } }
  footer a {
    color: white; }
  footer .tekst {
    font-size: 16px; }
    @media only screen and (max-width: 991px) {
      footer .tekst {
        font-size: 13px; } }
  footer .col-by-5 {
    margin-top: 50px; }
    footer .col-by-5 ul {
      padding-left: 0; }
    footer .col-by-5 li {
      list-style-type: none;
      padding: 5px; }
      footer .col-by-5 li a {
        color: white;
        font-size: 14px; }
  footer .category {
    padding: 5px;
    text-transform: uppercase;
    font-size: 18px;
    color: white;
    font-weight: bold; }
  footer p.tekst {
    margin-bottom: 19px; }
  footer .right p {
    float: left;
    margin-right: 18px;
    font-size: 18px; }
    @media only screen and (max-width: 991px) {
      footer .right p {
        float: initial; } }
    @media only screen and (max-width: 767px) {
      footer .right p {
        margin-top: 40px; } }
  footer .img-responsive {
    width: 17%;
    display: inline-block;
    padding: 2px;
    float: left; }
    @media only screen and (max-width: 1199px) {
      footer .img-responsive {
        width: 16%; } }
    @media only screen and (max-width: 767px) {
      footer .img-responsive {
        width: 22%;
        margin: 1.5%;
        padding: 0; } }

#optimal {
  padding-top: 15px;
  color: #aaaaaa;
  border-top: 1px solid rgba(255, 255, 255, 0.19);
  background-color: #1C1C1C;
  padding-bottom: 20px; }
  @media only screen and (max-width: 767px) {
    #optimal {
      text-align: center; } }
  #optimal i {
    display: inline-block;
    vertical-align: text-bottom; }
  #optimal .realization {
    text-align: right; }
    @media only screen and (max-width: 767px) {
      #optimal .realization {
        text-align: center;
        padding-top: 10px; } }
  #optimal * {
    color: #AAAAAA;
    font-size: 12px; }

/** FOOTER   END **/
@media only screen and (min-width: 1200px) {
  #daily_reading .col-lg-3 {
    width: 20%; }
  #daily_reading .col-lg-9 {
    width: 80%;
    padding-left: 4%; } }

.recommended a {
  display: inline;
  margin: 0 auto;
  text-align: center; }
  @media only screen and (max-width: 479px) {
    .recommended a {
      width: 100%; } }

.recommended .title {
  color: #444444;
  font-size: 29px;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 12px; }

.recommended img {
  width: 14%;
  margin: 10px 3%;
  border-radius: 0;
  opacity: .6;
  -webkit-filter: grayscale(100%);
  /* Chrome, Safari, Opera */
  filter: grayscale(100%); }
  @media only screen and (max-width: 991px) {
    .recommended img {
      width: 38%;
      margin: 10px 6%; } }
  @media only screen and (max-width: 479px) {
    .recommended img {
      width: auto;
      display: block;
      margin: 30px auto;
      max-width: 100%; } }

.recommended img:hover, .recommended img:active {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  /* Chrome, Safari, Opera */
  filter: grayscale(0%); }

#sliderTop {
  margin-top: -10px;
  margin-bottom: 30px; }
  #sliderTop .carousel-indicators {
    bottom: 3px; }
    #sliderTop .carousel-indicators li, #sliderTop .carousel-indicators .active {
      width: 26px;
      height: 26px;
      border-radius: 13px;
      border: 4px solid white;
      background-color: #eaeaea; }
    #sliderTop .carousel-indicators .active {
      background-color: #7BC623; }
  #sliderTop .carousel-caption {
    left: 0% !important;
    right: 50% !important;
    bottom: 60px !important;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.55);
    font-weight: bold;
    font-size: 18px; }

.logoImage {
  float: left;
  margin-right: 4%; }

.quote {
  font-family: "Lato";
  margin: 50px 11px 10px 11px;
  font-size: 26px;
  font-style: italic;
  line-height: 31px; }

.quoteAutor {
  text-align: right;
  margin: 25px 27px 53px 0; }

.widget1 {
  margin-top: 50px; }

.clear {
  clear: both; }

@media only screen and (max-width: 767px) {
  .col-xxs-12 {
    width: 100%; } }

.facebookWidget {
  position: fixed;
  top: 20%;
  right: -10px;
  z-index: 1; }
  .facebookWidget img {
    vertical-align: top;
    float: left; }
    .facebookWidget img:hover + .containerFb {
      width: 349px;
      border: 5px solid #4867AA; }
  .facebookWidget .containerFb {
    width: 0;
    float: left;
    transition: width 0.5s;
    border: 5px solid #4867AA; }
    .facebookWidget .containerFb:hover {
      width: 349px; }

.librus {
  position: fixed;
  top: 20%;
  left: 0; }

.activeMenu {
  color: black !important; }
  .activeMenu > a {
    color: black !important; }

.event {
  font-weight: 700;
  color: #7BC623; }

/*-- NEWS LIST START --*/
.singleNews {
  position: relative;
  display: block;
  padding: 15px; }
  .singleNews .img-responsive {
    margin: 0 auto; }
  .singleNews .description {
    color: #555;
    font-size: 16px;
    padding-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .singleNews h2 {
      margin-top: 0; } }
  .singleNews .buttonNews {
    position: absolute;
    bottom: 10px;
    right: 7px; }
    .singleNews .buttonNews * {
      font-size: 18px; }
    .singleNews .buttonNews .fa {
      float: none;
      font-size: 25px;
      display: inline-block;
      margin-left: 6px; }
  .singleNews .timeNews {
    margin-top: -3px;
    margin-bottom: 10px; }
    .singleNews .timeNews * {
      color: black;
      font-size: 15px;
      display: inline-block; }
    .singleNews .timeNews .fa {
      color: #7BC623;
      font-size: 18px;
      float: none;
      margin: 0; }

.singleNews:hover {
  background-color: #ff9800; }
  .singleNews:hover * {
    color: white !important; }

/*-- NEWS LIST   END --*/
/*-- NEWS SINGLE START --*/
/*-- NEWS SINGLE   END --*/
#productModule .categoriesChildren {
  display: none; }

#productModule #categoryTree {
  margin-top: 25px;
  padding-left: 10px; }
  #productModule #categoryTree a {
    display: table-cell;
    color: #000; }
  #productModule #categoryTree .expand {
    display: table-cell; }
  #productModule #categoryTree a.checked {
    color: #000;
    font-weight: bold; }
  #productModule #categoryTree li {
    list-style: none;
    padding-left: 0; }
    #productModule #categoryTree li ul {
      padding-left: 20px; }
    #productModule #categoryTree li .active {
      color: #7BC623;
      font-weight: bold; }
    #productModule #categoryTree li .expand i {
      font-size: 20px;
      line-height: 10px;
      padding-left: 10px; }

#productModule .productsList {
  margin-top: 20px; }
  #productModule .productsList a.product {
    display: block; }
    #productModule .productsList a.product img {
      padding: 5px; }
    #productModule .productsList a.product .description {
      padding: 15px; }
      #productModule .productsList a.product .description .name {
        font-size: 20px; }
  #productModule .productsList a.product:hover {
    background: #7BC623;
    color: #FFF; }

#productModule #product {
  padding-top: 15px; }
  #productModule #product .price {
    color: #7BC623;
    font-size: 20px; }
  #productModule #product .description {
    padding-top: 20px; }
  #productModule #product .images div img {
    padding: 10px; }

.listGalleryTitle {
  margin-bottom: 25px; }

.galleryLink {
  text-decoration: none !important;
  display: block;
  height: 250px;
  background-color: #2c3136;
  margin: 12px 7px;
  background-image: url(/media/default/noImageNews.jpg);
  background-size: cover;
  -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.28); }
  .galleryLink:hover .shadowed {
    height: 250px;
    background: rgba(0, 0, 0, 0.6);
    top: 0; }
  .galleryLink .shadowed {
    height: 150px;
    background: red;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    /* Standard syntax (must be last) */
    position: relative;
    top: 100px;
    transition: background .5s, top .5s, height .5s; }
    .galleryLink .shadowed .text {
      padding: 65px 10px 10px 10px;
      font-size: 25px;
      color: white;
      font-weight: 700;
      text-decoration: none !important;
      height: 135px;
      overflow: hidden; }

.fc-state-active.fc-month-button {
  display: none; }

.fc td {
  border-width: 0 !important; }

.fc-day-number {
  line-height: 20px;
  text-align: center;
  width: 100%;
  font-weight: 700;
  color: #444444; }

.fc-center h2 {
  font-size: 16px;
  font-weight: 700; }

.fc-sun .fc-day-number {
  color: #8C0000; }

.eventDay .fc-day-number {
  color: #7BC623; }

.fc-state-default.fc-button {
  margin-top: -5px;
  border: none;
  background-color: transparent;
  background-image: initial;
  background-repeat: initial;
  border-color: initial;
  color: initial;
  text-shadow: initial;
  box-shadow: initial; }

.fc-icon-left-single-arrow:after {
  font-size: 0 !important;
  height: 0;
  width: 0;
  border: 6px solid transparent;
  border-right: 8px solid black;
  top: -4px !important; }

.fc-icon-right-single-arrow:after {
  font-size: 0 !important;
  height: 0;
  width: 0;
  border: 6px solid transparent;
  border-left: 8px solid black;
  top: -4px !important; }

.fc-widget-header thead {
  background-color: black;
  color: white;
  line-height: 35px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700; }
  .fc-widget-header thead th {
    border-width: 0; }

.fc-head {
  border-bottom: 20px solid #eaeaea !important; }

.fc-basic-view .fc-body .fc-row {
  min-height: 37px !important; }

.fc-unthemed td.fc-today {
  background-color: initial !important; }

.fc-unthemed .fc-month-view td.fc-today.fc-day::after {
  content: 'border';
  border: 1px solid black;
  font-size: 0;
  width: 67%;
  margin-left: 11%;
  height: 22px;
  display: block; }

.fc-state-default.fc-button.fc-month-button {
  background-color: black;
  color: white;
  border: 0;
  border-radius: 0;
  font-weight: 700; }

.fc-month-view .fc-event-container .fc-event {
  border: 1px solid #ff9800 !important;
  border-radius: 0;
  margin: 1px !important;
  padding: 0 !important;
  height: 0; }
  .fc-month-view .fc-event-container .fc-event .fc-content .fc-title {
    font-size: 0; }

.fc-basicDay-view .fc-event-container .fc-event {
  background-color: initial !important;
  border: 1px solid black !important;
  border-radius: 0;
  margin: 0px 0 10px 0 !important;
  padding: 10px !important; }
  .fc-basicDay-view .fc-event-container .fc-event .fc-content .fc-title {
    font-size: 18px;
    color: #444444;
    weight: 700; }

@media only screen and (max-width: 768px) {
  .navbar-default {
    background-color: #ffffff;
    border-color: #FFFFFF; }
  .navbar-toggle .icon-bar {
    background-color: white;
    height: 3px; }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border: 0px solid #ffffff;
    background-color: #7BC623;
    margin-top: 10px; }
  .navbar-default .navbar-toggle, .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    padding: 8px;
    width: 44px;
    height: 40px;
    display: block;
    margin-right: 30%; }
  #header {
    text-align: center; }
    #header span {
      font-size: 21px !important; }
  #header span span {
    font-size: 24px !important; }
  #header a:hover, #header a:focus {
    background-color: #217db9; }
  .call_button {
    width: 44px;
    height: 40px;
    padding: 0;
    font-size: 31px;
    position: absolute;
    z-index: 10001;
    left: 0;
    display: inline;
    border: 1px solid transparent; }
  .btn-default:hover {
    border-color: #217db9; }
  #header #start_search {
    width: 44px; }
  .relative {
    position: relative;
    width: 152px;
    margin: 0 auto;
    margin-bottom: -50px; }
  .search_button_xs {
    position: relative;
    z-index: 10001;
    width: 44px;
    height: 40px;
    padding: 0;
    display: inline-block;
    margin-left: 54px;
    border: 1px solid transparent;
    font-size: 31px;
    font-size: 29px;
    line-height: 12px; }
  .navbar-toggle {
    position: relative;
    z-index: 10001;
    padding: 8px;
    width: 44px;
    height: 40px;
    background-color: #7BC623;
    margin: 0 !important;
    display: inline-block; }
  .panel-default .panel-heading {
    min-height: 58px;
    height: auto; }
  #myModal {
    z-index: 100000; }
  #search_word_xs {
    width: 100%;
    height: 40px;
    padding: 5px; }
  .title {
    font-size: 38px; }
  .panel-default .panel-heading {
    font-size: 18px; }
  .news .heading {
    font-size: 18px; }
  .carousel-inner {
    height: 100px; } }

#menu .dropdown-submenu {
  position: relative; }

nav {
  font-size: 16px; }
  nav .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    position: absolute;
    top: -18px;
    left: 42%; }
  nav .dropdown-menu {
    top: 90%;
    font-size: 16px;
    background-color: white;
    border-radius: 6px !important; }
    nav .dropdown-menu li:first-of-type {
      display: none; }
    nav .dropdown-menu a {
      color: #555; }
    nav .dropdown-menu a:hover {
      color: #7BC623;
      background-color: white; }
    nav .dropdown-menu .divider {
      margin: 5px 12px; }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu .dropdown-submenu .dropdown-menu::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: transparent;
    border: 10px solid transparent;
    border-right: 10px solid white;
    position: absolute;
    top: 10px;
    left: -19px; }
  .dropdown-submenu .dropdown-submenu .caret {
    display: none; }
  .dropdown-submenu .dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px; }
  .dropdown-submenu .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px; }
  .dropdown-submenu .dropdown-submenu .hover + .dropdown-menu {
    display: block; }
